/* -------- 공통요소 ---------- */

body {
  font-family: "Noto Sans", sans-serif;
}

.flexbox {
  display: flex;
  align-items: center;
}

.flexbox_sb {
  display: flex;
  justify-content: space-between;
}

.flexbox_fs {
  display: flex;
  justify-content: flex-start;
}

.Wrap {
  width: 1200px;
  margin: auto;
}

a {
  color: #000;
}

/* ---------------- header ------------------ */

header {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9999;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 6%);
}

/* header::after{content: ''; position: sticky; background: #000; top: 0; width: 100%; box-shadow: 0 4px 6px 0 rgb(0 0 0 / 6%); } */
/* .headerShadow {position: sticky; top: 0;  width: 100%; }
.headerShadow div{position: absolute; top: 20px;left: 0; width: 100%; background: #000; box-shadow: 0 4px 6px 0 rgb(0 0 0 / 6%);} */
.firstheader {
  height: 85px;
}

header h1 {
  width: 190px;
  margin-top: 8px;
}

header h1 img {
  width: 100%;
}

/* header nav */

.headernav {
  width: 350px;
  margin-left: 80px;
  height: 100%;
}

.mainnav {
  width: 100%;
  height: 100%;
}

.mainmenu {
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #000; */
}

.mainmenu:hover > a {
  position: relative;
  color: #1461ff;
}

.mainmenu:hover > a span {
  position: absolute;
  width: 100%;
  height: 2.5px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  background: #1461ff;
  /* border-bottom: 5px solid #f00; */
}

/* headerbtn */

.headerbtn li:first-child {
  margin-right: 40px;
}

.headerbtn a {
  font-size: 14px;
  color: #707070;
  font-weight: 700;
  display: block;
}

/* ----------------------main---------------------- */

/* sect1 */
main > .Wrap {
  position: relative;
}

#sect1 {
  background-image: url("../backgroundimg/mainbg.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 191px;
  position: relative;
  text-align: center;
}

#sect1 .Wrap {
  position: relative;
}

#sect1 h2 {
  color: #1461ff;
  font-size: 40px;
  font-weight: 700;
  margin-top: 53px;
  display: inline-block;
}

#sect1 p {
  font-size: 20px;
  line-height: 2;
  padding-bottom: 26px;
}

article:nth-child(even) {
  margin-top: -20px;
}
.maintitle {
  height: 218px;
  margin-top: 60px;
  position: relative;
}
.line,
.line::after,
.line::before {
  user-select: none;
  content: ".................................................................................................................................................";
  letter-spacing: 4px;
  color: #dcdcdc;
  position: absolute;

  width: 100%;
  right: 0;
}
.line {
  bottom: 0;
}
.line::after {
  bottom: 10px;
}
.line::before {
  bottom: 20px;
}
.maintitle .illust {
  width: 415px;
  position: absolute;
  right: 0;
}
.maintitle .illust img {
  width: 100%;
}
.maintitle h5 {
  color: #1461ff;
  font-size: 16px;
  margin-bottom: 5px;
}
.maintitle p {
  font-size: 28px;
}
.title {
  margin-top: 40px;
}
.textBr{
  font-size: 28px;
}
.title .textBr span {
  height: 50px;
  position: relative;
}
.title .textBr span img {
  position: absolute;
  top: -6px;
  /* border: 1px solid #000; */
}
.boxContainer {
  font-size: 18px;
}
.boxContent {
  margin-bottom: 26px;
}
.boxContainer h4 {
  margin-top: 70px;
  margin-bottom: 2px;
}
.caution {color:#999; font-size: 14px; }
.caution span{
  width: 15px; height: 15px;
  margin: 0 6px;
}
.caution span img{width: 100%;}
.caution a{ position: relative; transition: 1s;}
.caution:hover p{display: block; opacity: 1; position: absolute; left: 253px; transition: 1s;}
.caution p{display: none; opacity: 0; transition: 1s;}
.box {
  flex-wrap: wrap;
}
.icon:hover {
  background: #1462ff0b;
  border: 2px solid #1462ff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
}
.icontext {
  margin-bottom: 1px;
}
.icontext span {
  position: relative;
  margin-right: 15px;
}
.icontext span::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 7px;
  width: 7px;
  background: #1461ff;
  border-radius: 50%;
}

.icon {
  border: 2px solid #1462ff00;
  width: 280px;
  height: 85px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.11);
  background: #fff;
  margin-right: 26px;
}
.icon a {
  padding: 13px 20px 13px 23px;
  height: 100%;
}
.icon:nth-child(4) {
  margin-right: 0 !important;
}
.icon:nth-child(n + 5) {
  margin-top: 26px;
}
.icon img {
  width: 56px;
  height: 56px;
}

#sect section:nth-child(1) {
  margin-bottom: 100px;
}
#sect section:nth-child(2) {
  background: #fafafa;
  padding: 5px 0 100px 0;
}
.Wrap {
  position: relative;
}
.top_btn {
  position: fixed;
  right: 50px;
  bottom: 50px;
}
/* ------------------footer------------------ */

footer {
  background: #333;
  color: #fff;
  padding: 50px 0;
  font-size: 14px;
}

footer .col1 ul {
  width: 670px;
  padding-bottom: 30px;
}
.logo_w{margin-right: 30px;}
.detail {
  font-size: 14px;
}

.company {
  color: #999;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 5px;
}
.company strong {
  font-family: "Montserrat";
  font-weight: 500;
}
.company span::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 8px;
  background: #979797;
  margin: 4px 15px 0 16px;
}

.copyright {
  color: #999;
  line-height: 2;
  font-family: "Montserrat";
  font-weight: 500;
}

.col2 {
  text-align: right;
  color: #999;
}

.col2 p:first-child {
  font-size: 14px;
  font-weight: 700;
  color: #999;
}

.col2 h2 {
  color: #fff;
  font-size: 35px;
  font-family: "Montserrat";
  padding: 5px 0;
}

.col2 p:nth-child(3) {
  font-size: 13px;
  line-height: 1.5;
}

.col2 p:nth-child(3) span {
  font-family: "Montserrat";
  font-weight: 600;
}
